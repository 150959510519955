@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import './additional_styles';

// generic

body {
  padding-top: 2px; }

th, td {
  .form-control, .input, button {
    font-weight: normal;
    padding: 0px 6px;
    height: 22px; } }

.nav-pills > li:not(:last-child) {
  padding-right: 5px; }

.row {
  margin-bottom: 0;
  margin-top: 15px;
  margin-left: -15px;
  margin-right: -15px; }

.table {
  margin: 0; }

.full-height {
  height: calc(100vh - 64px); }

.flex-row-container {
  display: flex;
  flex-direction: column; }

.flex-row {
  flex-grow: 1;
  padding-top: 15px;
  padding-bottom: 15px; }

.flex-column-container {
  display: flex;
  flex-direction: row; }

.flex-column {
  flex-grow: 1;
  padding-top: 15px;
  padding-bottom: 15px; }

.flex-space-between {
  justify-content: space-between; }

.flex-center-items {
  align-items: center; }

.highlight {
  border: dashed 1px #000; }

ul.list-inline {
  margin-bottom: 0;

  li {
    padding-left:  0;
    padding-right: 0; } }

ul.pagination {
  margin-top:    10px;
  margin-bottom: 10px; }

table.table-fixed {
  table-layout: fixed; }

table.vertical-align-center td {
  vertical-align: middle !important; }

.table-responsive {
  border: none; }

.inline-selection .inputs {
  display: flex;

  .input {
    width: 100%; }

  .submit {
    width: 100px;
    margin-left: 10px; } }

.panel-body :first-child:not([type=checkbox]):not([type=radio]) {
  margin-top: 0; }

.panel-body > :last-child, .modal-body > :last-child {
  margin-bottom: 0; }

.modal-body.scrollable {
  max-height: calc(100vh - 176px);
  overflow: auto; }

form > .form-group:last-child {
  margin-bottom: 0; }

form .form-group .help-block {
  margin-bottom: 0;

  p:last-child {
    margin-bottom: 0; } }

.has-error .rw-widget,
.has-error .rw-widget.rw-state-focus,
.has-error .rw-widget.rw-state-focus:hover {}
.has-error .rw-widget .rw-widget-container {
  border-color: #a94442; }

// auth

@media (min-width: $screen-sm-min) {
  .auth-panel, .auth-alert, .contact-info {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto; }

  .contact-info.profile {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto; } }

.auth-alert, .contact-info {
  margin-bottom: 0; }

.auth-panel.left, .auth-alert.left, .contact-info.left {
  margin-left: auto;
  margin-right: 0; }

.auth-panel.right, .auth-alert.right, .contact-info.right {
  margin-left: 0;
  margin-right: auto; }

// admin tables

.id-column {
  width: 56px; }

.one-action {
  .actions-column {
    width: 32px; } }

.two-actions {
  .actions-column {
    width: 64px; } }

.three-actions {
  .actions-column {
    width: 96px; } }

// menu

#menu {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto; }

.menu-button {
  width: 100px;
  height: 100%; }

.btn.menu-submit {
  opacity: 1; }

// transaction history

#transaction-history {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto; }

#transaction-balances {
  @media (min-width: $screen-sm-min) {
    font-size: 28px; }

  @media (min-width: 700px) and (max-width: $screen-xs-max) {
    font-size: 24px; }

  @media (min-width: 600px) and (max-width: 700px) {
    font-size: 22px; }

  @media (min-width: 550px) and (max-width: 600px) {
    font-size: 20px; }

  @media (min-width: 500px) and (max-width: 550px) {
    font-size: 18px; }

  @media (min-width: 400px) and (max-width: 500px) {
    font-size: 16px; }

  @media (min-width: 0px) and (max-width: 400px) {
    font-size: 14px; } }
